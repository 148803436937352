import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessService } from '@cm/ui-modules';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface MediaUploadResponse {
  fileName: string;
  fileUrl: string;
  fileUuid: string;
  mediaAssetId: number;
}

export interface UploadSuccessEvent {
  response: MediaUploadResponse;
  fileType: string;
  project?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaUploadService {
  private uploadSuccess = new Subject<UploadSuccessEvent>();

  uploadSuccess$ = this.uploadSuccess.asObservable();

  constructor(
    private readonly http: HttpClient,
    private accessService: AccessService
  ) {}

  uploadFile(file: File, project?: string): Observable<MediaUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    if (project) {
      formData.append('project', project);
    }

    const user = this.accessService.getUser();
    if (!user || !user.access_token) {
      throw new Error('No access token available');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user.access_token}`
    });

    return this.http.post<MediaUploadResponse>('/api/media/file/upload', formData, { headers })
      .pipe(
        tap(response => {
          this.uploadSuccess.next({
            response,
            fileType: this.getFileType(file.name),
            project
          });
        })
      );
  }

  getMediaAssetUrl(value: string): string {
    return `/mediaassets/${value}`;
  }

  isImageFile(filename: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
    const ext = filename.toLowerCase().substring(filename.lastIndexOf('.'));
    return imageExtensions.includes(ext);
  }

  isVideoFile(filename: string): boolean {
    const videoExtensions = ['.mp4'];
    const ext = filename.toLowerCase().substring(filename.lastIndexOf('.'));
    return videoExtensions.includes(ext);
  }

  isPdfFile(filename: string): boolean {
    return filename.toLowerCase().endsWith('.pdf');
  }

  isValidFileType(filename: string): boolean {
    return this.isImageFile(filename) || this.isVideoFile(filename) || this.isPdfFile(filename);
  }

  private getFileType(filename: string): string {
    if (this.isImageFile(filename)) {
      return 'image';
    } else if (this.isVideoFile(filename)) {
      return 'video';
    } else if (this.isPdfFile(filename)) {
      return 'pdf';
    }
    return 'unknown';
  }
}
