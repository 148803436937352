{
  "name": "admin-panel",
  "version": "1.5.60",
  "scripts": {
    "preinstall": "if [ \"$NODE_ENV\" = \"development\" ]; then npm run install:local; else npm run install:registry; fi",
    "install:local": "npm install --no-save \"file:../frontend-module-common/dist/ui-modules/cm-ui-modules-0.30.58.tgz\"",
    "install:registry": "npm install @cm/ui-modules@0.30.58",
    "ng": "ng",
    "clean": "rm -rf dist/",
    "update-ui": "npm update @cm/ui-modules",
    "dev": "ng serve --port 4200",
    "start": "npm run install:local && npm run dev",
    "serve": "ng serve --port 4289",
    "serve-dev": "ng serve --port 4200",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "gql-gen": "gql-gen --config src/app/shared/graphql/codegen.ts",
    "codegen": "gql-gen --config src/app/shared/graphql/codegen.ts",
    "add-translations": "node scripts/add-translations.js",
    "component": "cd ../frontend-module-common && npm run pack-ui && cd ../frontend-admin-panel && npm run install:local && npm run start"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "*.{ts,scss,md,html}": [
      "prettier --write",
      "git add"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.0",
    "@angular/cdk": "^17.2.1",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/material": "^17.2.1",
    "@angular/material-moment-adapter": "^17.2.1",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/router": "^17.2.0",
    "@anthropic-ai/sdk": "^0.32.1",
    "@apollo/client": "^3.0.0",
    "@cm/ui-modules": "^0.30.58",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular-pipes": "^10.0.0",
    "apollo-angular": "^6.0.0",
    "diacritics": "^1.3.0",
    "dotenv": "^16.4.5",
    "file-saver": "^2.0.5",
    "graphql": "^16",
    "graphql-cli": "^4.1.0",
    "graphql-tag": "^2.12.6",
    "jwt-decode": "^4.0.0",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ngx-cookie-service": "^17.1.0",
    "ngx-mat-select-search": "^7.0.5",
    "ngx-quill": "^25.0.0",
    "quill": "^2.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.0",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.3.6",
    "@angular/compiler-cli": "^17.2.0",
    "@graphql-codegen/cli": "5.0.2",
    "@graphql-codegen/fragment-matcher": "^5.0.2",
    "@graphql-codegen/introspection": "4.0.3",
    "@graphql-codegen/typescript": "^4.1.2",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.4.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "@typescript-eslint/eslint-plugin": "^6.19.0",
    "@typescript-eslint/eslint-plugin-tslint": "^7.0.2",
    "@typescript-eslint/parser": "^6.19.0",
    "autoprefixer": "^10.4.17",
    "codelyzer": "^6.0.2",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.2",
    "postcss": "^8.4.35",
    "prettier": "^3.2.5",
    "tailwindcss": "^3.4.17",
    "typescript": "~5.3.2"
  }
}